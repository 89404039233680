import React, { useState, useContext, useEffect, useRef } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import ReactDOM from 'react-dom';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';
import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';
import Alert from '../../../components/Alert';
import GuestDetailsDescription from './GuestDetailsDescription';
import GuestDetailsForm from './GuestDetailsForm';
import GuestDetailsQuantity from './GuestDetailsQuantity';
import GuestDetailsButtons from './GuestDetailsButtons';

import type { FormValues } from './GuestDetailsForm';

type GuestDetailsProps = {
  onNext: (arg0?: string) => void;
};

const GuestDetails = ({ onNext }: GuestDetailsProps) => {
  const {
    bookingItems: [bookingItems, setBookingItems],
    bookingData: [bookingData, setBookingData],
    editPackage: [editPackage],
    isEditMode,
    isDecreasingJumpers: [isDecreasingJumpers, setIsDecreasingJumpers],
    hasBeenToReview: [hasBeenToReview, setHasBeenToReview]
  } = useContext(DataContext) as DataContextValueType;

  const {
    activeStep,
    steps: [stepsData, setStepsData],
    stepWillChange: [stepWillChange, setStepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const [jumpersNumber, setJumpersNumber] = useState(editPackage?.quantity || 10);
  const [nonJumpersNumber, setNonJumpersNumber] = useState(bookingData.extra.nonJumpersNumber || 0);
  const [guestOfHonorCount, setGuestOfHonorCount] = useState(
    bookingData.extra.guestOfHonorCount || 1
  );
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isOverVenueLimit, setIsOverVenueLimit] = useState(false);
  const [hasAddonsSelected, setHasAddonsSelected] = useState(
    bookingItems.length > 0 ? true : false
  );
  const [remainingCapacityAlert, setRemainingCapacityAlert] = useState(false);
  const [showResetButton, setShowResetButton] = useState(false);
  const [isIncreasingGuests, setIsIncreasingGuests] = useState(false);
  const [hasIncreasedGuests, setHasIncreasedGuests] = useState(false);

  // Track the values at time of date/time selection
  const [timeSelectionJumpers, setTimeSelectionJumpers] = useState(0);
  const [timeSelectionNonJumpers, setTimeSelectionNonJumpers] = useState(0);

  const formRef = useRef<HTMLFormElement>(null);

  const getCapacityLimit = () => {
    console.log('--- getCapacityLimit ---');
    console.log('partyCapacity:', bookingData.extra.partyCapacity);
    return bookingData.extra.partyCapacity || 0;
  };

  const checkVenueLimit = (jumpersNumber: number, nonJumpersNumber: number) => {
    const capacityLimit = getCapacityLimit();

    if (capacityLimit && jumpersNumber + nonJumpersNumber > capacityLimit) {
      setIsOverVenueLimit(true);
      return;
    }

    setIsOverVenueLimit(false);
  };

  const checkDecreasingJumpers = (jumpersNumber: number) => {
    console.log('--- checkDecreasingJumpers ---');
    console.log('Current bookingData.extra.jumpersNumber:', bookingData.extra.jumpersNumber);
    console.log('New jumpersNumber:', jumpersNumber);
    console.log('stepsData[2].done:', stepsData[2].done);
    console.log('hasAddonsSelected:', hasAddonsSelected);

    if (stepsData[2].done && hasAddonsSelected && jumpersNumber < bookingData.extra.jumpersNumber) {
      setIsDecreasingJumpers(true);
      setIsIncreasingGuests(false);
    } else {
      setIsDecreasingJumpers(false);
    }
  };

  const checkIncreasingGuests = (jumpersNum: number, nonJumpersNum: number) => {
    if (bookingData.extra.bookingDate && bookingData.extra.startTime) {
      const currentTotal = jumpersNum + nonJumpersNum;
      // Compare against the values at time of date/time selection
      const initialTotal = timeSelectionJumpers + timeSelectionNonJumpers;

      if (currentTotal > initialTotal) {
        setIsIncreasingGuests(true);
        setHasIncreasedGuests(true);
        setIsDecreasingJumpers(false);
      } else {
        setIsIncreasingGuests(false);
      }
    }
  };

  const handleJumpersChange = (newValue: number) => {
    checkDecreasingJumpers(newValue);
    checkIncreasingGuests(newValue, nonJumpersNumber);
    setJumpersNumber(newValue);
    checkVenueLimit(newValue, nonJumpersNumber);

    const newRemainingCapacity = bookingData.extra.partyCapacity
      ? bookingData.extra.partyCapacity - (newValue + nonJumpersNumber)
      : 0;

    console.log('--- GuestDetails: handleJumpersChange ---');
    console.log('Party capacity:', bookingData.extra.partyCapacity);
    console.log('New jumpers:', newValue);
    console.log('Current non-jumpers:', nonJumpersNumber);
    console.log('New remaining capacity:', newRemainingCapacity);

    setBookingData(prevData => ({
      ...prevData,
      extra: {
        ...prevData.extra,
        jumpersNumber: newValue,
        remainingCapacity: newRemainingCapacity
      }
    }));
  };

  const handleNonJumpersChange = (newValue: number) => {
    checkIncreasingGuests(jumpersNumber, newValue);
    setNonJumpersNumber(newValue);
    checkVenueLimit(jumpersNumber, newValue);

    const newRemainingCapacity = bookingData.extra.partyCapacity
      ? bookingData.extra.partyCapacity - (jumpersNumber + newValue)
      : 0;

    console.log('--- GuestDetails: handleNonJumpersChange ---');
    console.log('Party capacity:', bookingData.extra.partyCapacity);
    console.log('Current jumpers:', jumpersNumber);
    console.log('New non-jumpers:', newValue);
    console.log('New remaining capacity:', newRemainingCapacity);

    setBookingData(prevData => ({
      ...prevData,
      extra: {
        ...prevData.extra,
        nonJumpersNumber: newValue,
        remainingCapacity: newRemainingCapacity
      }
    }));
  };

  const addGuestOfHonor = () => {
    if (guestOfHonorCount === 3) return;
    setGuestOfHonorCount(guestOfHonorCount + 1);
  };

  const removeGuestOfHonor = () => {
    setGuestOfHonorCount(guestOfHonorCount - 1);
  };

  useEffect(() => {
    checkBookingItems();
  }, [guestOfHonorCount]);

  const checkBookingItems = () => {
    console.log('--- checkBookingItems ---');
    console.log('Current jumpers:', jumpersNumber);
    console.log('Current bookingItems:', bookingItems);

    const addonsItShouldHave = Math.floor(jumpersNumber / 5);
    console.log('Addons it should have:', addonsItShouldHave);

    // Separate included and non-included items
    const includedItems = bookingItems.filter(item => item.isIncluded);
    const nonIncludedItems = bookingItems.filter(item => !item.isIncluded);

    console.log('Included items before:', includedItems);
    console.log('Non-included items:', nonIncludedItems);

    // Filter included items based on jumper count
    const reviewedIncludedItems = includedItems.filter(item => {
      // Keep items without an index (these aren't tied to jumper count)
      if (item.index === undefined) return true;
      // Keep items with index less than what we should have
      if (item.index < addonsItShouldHave) return true;
      return false;
    });

    console.log('Included items after filtering:', reviewedIncludedItems);

    // Combine filtered included items with non-included items
    const updatedBookingItems = [...reviewedIncludedItems, ...nonIncludedItems];
    console.log('Final booking items:', updatedBookingItems);

    setBookingItems(updatedBookingItems);
  };

  // Call checkBookingItems when jumpers number changes and we have addons selected
  useEffect(() => {
    if (hasAddonsSelected && stepsData[2].done) {
      checkBookingItems();
    }
  }, [jumpersNumber]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    console.log('--- onSubmit Debug ---');
    console.log('remainingCapacityAlert:', remainingCapacityAlert);
    console.log('isIncreasingGuests:', isIncreasingGuests);
    console.log('hasIncreasedGuests:', hasIncreasedGuests);
    console.log('bookingData.extra.bookingDate:', bookingData.extra.bookingDate);
    console.log('bookingData.extra.startTime:', bookingData.extra.startTime);

    if (hasAddonsSelected && isDecreasingJumpers) {
      checkBookingItems();
    }

    setBookingData({
      ...bookingData,
      customer: {
        ...bookingData.customer,
        email: isEditMode ? bookingData.customer.email : data.email
      },
      extra: {
        ...bookingData.extra,
        guestOfHonorCount,
        jumpersNumber,
        nonJumpersNumber,
        guestOfHonorFirstName: data.guestOfHonorFirstName,
        guestOfHonorDob: data.guestOfHonorDob,
        secondGuestOfHonorFirstName:
          guestOfHonorCount >= 2 ? data.secondGuestOfHonorFirstName : undefined,
        secondGuestOfHonorDob: guestOfHonorCount >= 2 ? data.secondGuestOfHonorDob : undefined,
        thirdGuestOfHonorFirstName:
          guestOfHonorCount >= 3 ? data.thirdGuestOfHonorFirstName : undefined,
        thirdGuestOfHonorDob: guestOfHonorCount >= 3 ? data.thirdGuestOfHonorDob : undefined
      }
    });

    if (stepWillChange.willChange && stepWillChange.stepValue) {
      goToStep(stepWillChange.stepValue);
      return;
    }

    // First check if we need a new date/time
    if (remainingCapacityAlert) {
      if (isEditMode) {
        const updatedSteps = stepsData.map(step => {
          if (step.value === 'select-date-time') {
            return { ...step, done: false };
          }
          if (step.value === 'select-addons') {
            return { ...step, done: false, disabled: true };
          }
          return step;
        });
        setStepsData(updatedSteps);
      }
      onNext('select-date-time');
      return;
    }

    // If we have date/time and no capacity issues, go to addons
    if (bookingData.extra.bookingDate && bookingData.extra.startTime) {
      if (!isEditMode) {
        stepsData[3].done = false;
        stepsData[4].done = false;
        setStepsData([...stepsData]);
      }
      onNext('select-addons');
      return;
    }

    onNext();
  };

  const handlePrev = () => {
    const fallbackUrl = 'https://www.skyzone.com/parties/';
    const parkUrl = bookingData.extra.parkNameUrl
      ? `https://www.skyzone.com/${bookingData.extra.parkNameUrl}/parties/`
      : fallbackUrl;

    window.location.href = parkUrl;
  };

  const handleStepChange = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    setStepWillChange({ willChange: false });
  };

  // Update this useEffect to handle time selection values
  useEffect(() => {
    console.log('--- Time Selection Values Update ---');
    console.log('Current timeSelectionJumpers:', timeSelectionJumpers);
    console.log('Current timeSelectionNonJumpers:', timeSelectionNonJumpers);
    console.log('Current jumpersNumber:', jumpersNumber);
    console.log('Current nonJumpersNumber:', nonJumpersNumber);
    console.log('hasBeenToReview:', hasBeenToReview);
    console.log('activeStep:', activeStep?.value);

    if (bookingData.extra.bookingDate && bookingData.extra.startTime) {
      // Only set initial reference values if we haven't been to review
      if (!hasBeenToReview && timeSelectionJumpers === 0 && timeSelectionNonJumpers === 0) {
        setTimeSelectionJumpers(jumpersNumber);
        setTimeSelectionNonJumpers(nonJumpersNumber);
        setHasIncreasedGuests(false);
      }
    } else {
      // Reset when date/time is cleared
      setTimeSelectionJumpers(0);
      setTimeSelectionNonJumpers(0);
      setHasIncreasedGuests(false);
      setHasBeenToReview(false);
    }
  }, [bookingData.extra.bookingDate, bookingData.extra.startTime]);

  // Add this effect to handle returning from review
  useEffect(() => {
    if (activeStep?.value === 'enter-guest-details' && hasBeenToReview) {
      console.log('--- Returning from Review ---');
      console.log('Setting new reference values:');
      console.log('Jumpers:', jumpersNumber);
      console.log('Non-jumpers:', nonJumpersNumber);

      setTimeSelectionJumpers(jumpersNumber);
      setTimeSelectionNonJumpers(nonJumpersNumber);
      setHasIncreasedGuests(false);
    }
  }, [activeStep?.value]);

  // Update the useEffect that handles showResetButton
  useEffect(() => {
    const capacityLimit = getCapacityLimit();
    if (capacityLimit) {
      const remainingCapacity = capacityLimit - (jumpersNumber + nonJumpersNumber);
      const isOverLimit = remainingCapacity < 0;

      // Only show reset button if either jumpers or non-jumpers have changed
      const shouldShowReset = !!(
        bookingData.extra.bookingDate &&
        bookingData.extra.startTime &&
        (jumpersNumber !== timeSelectionJumpers || nonJumpersNumber !== timeSelectionNonJumpers)
      );

      // Only update states if they've changed
      const statesNeedUpdate =
        remainingCapacityAlert !== isOverLimit ||
        isOverVenueLimit !== isOverLimit ||
        showResetButton !== shouldShowReset ||
        bookingData.extra.remainingCapacity !== remainingCapacity;

      if (statesNeedUpdate) {
        ReactDOM.unstable_batchedUpdates(() => {
          setRemainingCapacityAlert(isOverLimit);
          setIsOverVenueLimit(isOverLimit);
          setShowResetButton(shouldShowReset);
          setBookingData(prevData => ({
            ...prevData,
            extra: {
              ...prevData.extra,
              remainingCapacity
            }
          }));
        });
      }
    }
  }, [
    bookingData.extra.partyCapacity,
    bookingData.extra.bookingDate,
    bookingData.extra.startTime,
    jumpersNumber,
    nonJumpersNumber,
    timeSelectionJumpers,
    timeSelectionNonJumpers
  ]);

  const getRemainingCapacity = () => {
    const capacityLimit = getCapacityLimit();
    console.log('--- getRemainingCapacity calculations ---');
    console.log('Total capacity:', capacityLimit);
    console.log('Current jumpers:', jumpersNumber);
    console.log('Current non-jumpers:', nonJumpersNumber);
    console.log('Current total:', jumpersNumber + nonJumpersNumber);

    if (!capacityLimit) return 0;

    const remainingCapacity = capacityLimit - (jumpersNumber + nonJumpersNumber);
    console.log('Final remaining:', remainingCapacity);
    return remainingCapacity;
  };

  useEffect(() => {
    if (bookingItems.length > 0) {
      setHasAddonsSelected(true);
    }
  }, [bookingItems]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'enter-guest-details' && isEditMode) {
      setNextDisabled(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'enter-guest-details' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  // Add this useEffect to initialize remainingCapacity in edit mode
  useEffect(() => {
    if (isEditMode && bookingData.extra.partyCapacity) {
      const totalGuests = (jumpersNumber || 10) + (nonJumpersNumber || 0);
      const initialRemainingCapacity = bookingData.extra.partyCapacity - totalGuests;

      console.log('--- GuestDetails: Initialize Edit Mode ---');
      console.log('Party capacity:', bookingData.extra.partyCapacity);
      console.log('Current jumpers:', jumpersNumber || 10);
      console.log('Current non-jumpers:', nonJumpersNumber || 0);
      console.log('Total guests:', totalGuests);
      console.log('Initial remaining capacity:', initialRemainingCapacity);

      setBookingData(prevData => ({
        ...prevData,
        extra: {
          ...prevData.extra,
          remainingCapacity: initialRemainingCapacity
        }
      }));
    }
  }, [isEditMode, bookingData.extra.partyCapacity]);

  // Add this useEffect to reset addon selection state when needed
  useEffect(() => {
    if (!isDecreasingJumpers) {
      setHasAddonsSelected(bookingItems.length > 0);
    }
  }, [isDecreasingJumpers, bookingItems]);

  // Update this useEffect to handle steps state when capacity changes
  useEffect(() => {
    if (isEditMode) {
      const needsNewDateTime = remainingCapacityAlert || (isIncreasingGuests && hasIncreasedGuests);

      const updatedSteps = stepsData.map(step => {
        if (step.value === 'select-date-time') {
          return { ...step, disabled: false }; // Always keep date/time enabled
        }
        if (step.value === 'select-addons') {
          if (needsNewDateTime) {
            return { ...step, done: false, disabled: true };
          }
          if (isDecreasingJumpers) {
            return { ...step, done: false, disabled: false };
          }
        }
        return step;
      });
      setStepsData(updatedSteps);
    }
  }, [
    remainingCapacityAlert,
    isIncreasingGuests,
    hasIncreasedGuests,
    isDecreasingJumpers,
    isEditMode
  ]);

  const hasJumperCountChanged = jumpersNumber !== timeSelectionJumpers;

  return (
    <>
      <GuestDetailsDescription />
      <div className="guest-details container-sm" id="guest-details">
        <img
          alt="confetti"
          className="guest-details__confetti"
          src="/images/confetti.png"
          width={161}
          height={180}
        />
        <GuestDetailsForm
          formRef={formRef}
          setNextDisabled={setNextDisabled}
          onSubmit={onSubmit}
          guestOfHonorCount={guestOfHonorCount}
          addGuestOfHonor={addGuestOfHonor}
          removeGuestOfHonor={removeGuestOfHonor}
        />
        <GuestDetailsQuantity
          jumpersNumber={jumpersNumber}
          nonJumpersNumber={nonJumpersNumber}
          handleJumpersChange={handleJumpersChange}
          handleNonJumpersChange={handleNonJumpersChange}
          isOverVenueLimit={isOverVenueLimit}
        />
        {isOverVenueLimit && isDecreasingJumpers && (
          <Alert type="default">
            We like how you party! But your Guest list exceeds our party space capacity. Either
            adjust your counts down, go back to select a new time and date or give us a call at
            {bookingData.extra.parkPhoneNumber} to make sure we reserve a large enough space for
            your group!
          </Alert>
        )}
        {isOverVenueLimit && !isDecreasingJumpers && (
          <Alert type="default">
            We like how you party! But your guest list has exceeded our party space capacity. Either
            adjust your counts down or give us a call at {bookingData.extra.parkPhoneNumber} to make
            sure we reserve a large enough space for your group!
          </Alert>
        )}
        {bookingData.extra.bookingDate && bookingData.extra.startTime && remainingCapacityAlert && (
          <Alert
            title={<strong>Number of guests has changed</strong>}
            type="error"
            className="guest-details__jumpers-increase-alert"
          >
            The number of guests has changed and you will be required to choose a new date and time
            before completing your order.
          </Alert>
        )}
        {bookingData.extra.bookingDate &&
          bookingData.extra.startTime &&
          !remainingCapacityAlert &&
          hasIncreasedGuests && (
            <div className="guest-details__remaining-capacity">
              <div className="guest-details__remaining-capacity-info">
                <p>
                  {`Number of spots remaining on ${bookingData.extra.bookingDate} at ${bookingData.extra.startTime}`}
                </p>
                <div className="guest-details__remaining-capacity-info-number">
                  {getRemainingCapacity()}
                </div>
              </div>
              <button
                className="guest-details__reset-jumpers-selections"
                onClick={() => {
                  setJumpersNumber(timeSelectionJumpers);
                  setNonJumpersNumber(timeSelectionNonJumpers);
                  setIsIncreasingGuests(false);
                }}
                disabled={!showResetButton}
              >
                Reset selections
              </button>
            </div>
          )}
        {hasJumperCountChanged &&
          !remainingCapacityAlert &&
          bookingData.extra.bookingDate &&
          bookingData.extra.startTime && (
            <Alert type="error" className="guest-details__jumpers-increase-alert">
              New jumper count? No problem! Please review your add-ons and package details next to
              make sure everything looks party perfect.
            </Alert>
          )}
      </div>
      <GuestDetailsButtons
        handlePrev={handlePrev}
        nextDisabled={nextDisabled}
        isOverVenueLimit={isOverVenueLimit}
        isDecresingJumpers={isDecreasingJumpers}
        remainingCapacityAlert={remainingCapacityAlert}
        isIncreasingGuests={isIncreasingGuests}
      />
    </>
  );
};

export default GuestDetails;
