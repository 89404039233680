import React, { useContext } from 'react';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type GuestDetailsButtonsProps = {
  handlePrev: () => void;
  nextDisabled: boolean;
  isOverVenueLimit: boolean;
  isDecresingJumpers?: boolean;
  remainingCapacityAlert?: boolean;
  isIncreasingGuests?: boolean;
};

const GuestDetailsButtons = ({
  handlePrev,
  nextDisabled,
  isOverVenueLimit,
  isDecresingJumpers,
  remainingCapacityAlert
}: GuestDetailsButtonsProps) => {
  const {
    venueInfo,
    isEditMode,
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  const getButtonLabel = () => {
    // If date/time is selected and there are capacity issues, show select new date/time
    if (remainingCapacityAlert && bookingData.extra.bookingDate && bookingData.extra.startTime) {
      return 'Select New Date & Time';
    }
    // Handle decreasing jumpers scenario
    if (isDecresingJumpers) {
      return 'Continue to Add ons';
    }
    // If date/time is already selected and no capacity issues, go to add-ons
    if (bookingData.extra.bookingDate && bookingData.extra.startTime) {
      return 'Continue to Add ons';
    }
    // If no date/time selected yet, show select date/time
    return 'Next: Select date & time';
  };

  const isButtonDisabled = () => {
    // If we have a date/time selected and we're over venue limit, enable the button to select new date/time
    if (
      isOverVenueLimit &&
      remainingCapacityAlert &&
      bookingData.extra.bookingDate &&
      bookingData.extra.startTime
    ) {
      return false;
    }

    // If no date/time selected and we're over venue limit, disable the button
    if (isOverVenueLimit && !bookingData.extra.bookingDate && !bookingData.extra.startTime) {
      return true;
    }

    return nextDisabled || !venueInfo.name || (isOverVenueLimit && !remainingCapacityAlert);
  };

  return (
    <div className="step-container__buttons container">
      {!isEditMode && (
        <Button onClick={handlePrev} kind="ghost" leftIcon={<Svg content="arrow-left" />}>
          Back
        </Button>
      )}

      <Button
        type="submit"
        form="enter-guest-details"
        disabled={isButtonDisabled()}
        loading={!venueInfo.name}
        rightIcon={<Svg content="double-chevron-right" />}
      >
        {getButtonLabel()}
      </Button>
    </div>
  );
};

export default GuestDetailsButtons;
