/* eslint-disable @typescript-eslint/no-explicit-any */

const replaceSpecialChars = (str?: string) => {
  if (!str) return '';
  return str.replace(/[\n\r\t\v\f]/g, ' ').replace(/:/g, '-');
};

const insertSpaceBetweenWords = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
};

const removeSpaceBetweenWords = (str: string) => {
  return str.replace(/ /g, '');
};

export const turnJsonToHumanReadableString = (data: any) => {
  const humanReadableString = Object.keys(data)
    .map(key => {
      let value = data[key];
      if (key === 'extraInfo') value = replaceSpecialChars(value);
      return `${insertSpaceBetweenWords(key)}: ${value}`;
    })
    .join('\n');

  return humanReadableString;
};

export const turnHumanReadableStringToJson = (humanReadableString: string) => {
  const firstPart = humanReadableString.split('Notes:')[0];
  const secondPart = humanReadableString.split('Notes:')[1];

  const arrayOfStrings = firstPart.split('\n');

  const jsonData = arrayOfStrings.reduce((acc: any, curr) => {
    const currArr = curr.split(': ');

    const key = removeSpaceBetweenWords(currArr[0]);
    let value = currArr[1] as any;

    if (key === 'nonJumpersNumber') value = parseInt(value, 10);
    if (value === 'true') value = true;
    if (value === 'false') value = false;
    if (value === 'undefined') value = undefined;

    if (key === '') return acc;

    acc[key] = value;
    return acc;
  }, {});

  // support old way
  if (JSON.stringify(jsonData) === '{}' && firstPart) {
    const withoutSpecialChars = firstPart.replace(/[\n\r\t\v\f]/g, ' ');
    return JSON.parse(withoutSpecialChars);
  }

  jsonData.Notes = secondPart === undefined ? '' : secondPart;

  return jsonData;
};
