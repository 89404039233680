import React, { useState, useRef, useEffect } from 'react';
import type { ReactElement } from 'react';

export type AccordionData = {
  heading: string;
  copy: ReactElement;
};

function Accordion({ heading, copy }: AccordionData) {
  const [isActive, setIsActive] = useState(false);
  const contentEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive && contentEl.current) {
      contentEl.current.focus();
    }
  }, [isActive]);

  return (
    <div className={`accordion${isActive ? ' accordion--active' : ''}`}>
      <button
        className="accordion__heading-container"
        onClick={() => setIsActive(!isActive)}
        role="button"
        aria-expanded={isActive}
        aria-label={heading}
      >
        <h3 className="accordion__heading">{heading}</h3>
        {isActive ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 2"
            className="accordion__icon"
            width="20"
            height="20"
          >
            <path strokeWidth="0" fill="currentColor" d="M13.781 1.988V.04H.219v1.948z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14"
            className="accordion__icon"
            width="20"
            height="20"
          >
            <path
              strokeWidth="0"
              fill="currentColor"
              d="M7.967 14V7.988h5.814V6.04H7.967V0H6.005v6.04H.219v1.948h5.786V14z"
            />
          </svg>
        )}
      </button>
      <div
        className="accordion__panel"
        style={{
          blockSize:
            isActive && contentEl.current ? (contentEl.current as HTMLElement).offsetHeight : 0
        }}
      >
        <div
          className="accordion__content"
          tabIndex={0}
          ref={contentEl}
          role="region"
          aria-label={copy.props.children}
          aria-expanded={isActive}
        >
          {copy}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
