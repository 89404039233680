import React, { useContext } from 'react';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

function ContactRow() {
  const {
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  const handleChatClick = () => {
    //check if the brandembassy function is defined
    if (typeof brandembassy === 'function') brandembassy('openChatWindow');
  };

  return (
    <section className="contact-row" id="contact-help">
      <div className="container">
        <h2 className="contact-row__title">Need help with your reservation?</h2>
        <div className="contact-row__links-container">
          <button
            className="contact-row__link"
            role="button"
            aria-label={`Click to chat with us`}
            onClick={handleChatClick}
          >
            <div className="contact-row__info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                className="contact-row__icon contact-row__icon--chat"
                width="22"
                height="32"
              >
                <path
                  d="M72 48c-13.3 0-24 10.7-24 24v176c0 13.3 10.7 24 24 24h48c13.3 0 24 10.7 24 24v19.2l58.7-39.2c3.9-2.6 8.6-4 13.3-4h128c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H72zM0 72C0 32.2 32.2 0 72 0h272c39.8 0 72 32.2 72 72v176c0 39.8-32.2 72-72 72H223.3l-90 60c-7.4 4.9-16.8 5.4-24.6 1.2S96 368.9 96 360v-40H72c-39.8 0-72-32.2-72-72V72zm256 280h48v24c0 13.3 10.7 24 24 24h96c4.7 0 9.4 1.4 13.3 4l58.7 39.2V424c0-13.3 10.7-24 24-24h48c13.3 0 24-10.7 24-24V200c0-13.3-10.7-24-24-24H448v-48h120c39.8 0 72 32.2 72 72v176c0 39.8-32.2 72-72 72h-24v40c0 8.9-4.9 17-12.7 21.2s-17.3 3.7-24.6-1.2l-90-60H328c-39.8 0-72-32.2-72-72v-24zM147.9 96.1c5.5-15.4 20.1-25.7 36.4-25.7h41.3c24.2 0 43.7 19.6 43.7 43.7 0 15.7-8.4 30.1-22 37.9L224 165.4v.3c0 8.8-7.2 16-16 16s-16-7.2-16-16v-9.5c0-5.7 3.1-11 8-13.9l31.4-18c3.6-2.1 5.9-6 5.9-10.2 0-6.5-5.3-11.7-11.7-11.7h-41.3c-2.8 0-5.3 1.8-6.3 4.4l-.3.9c-3 8.3-12.1 12.7-20.4 9.7s-12.7-12.1-9.7-20.4l.3-.9zM185.3 228a22.7 22.7 0 1 1 45.3 0 22.7 22.7 0 1 1-45.3 0z"
                  fill="currentColor"
                />
              </svg>
              <div className="contact-row__number-container">
                <span className="contact-row__number-container__text">Chat</span>
              </div>
            </div>
          </button>
          {bookingData.extra.guestServicesTextNumber && (
            <a
              className="contact-row__link"
              href={`sms:${bookingData.extra.guestServicesTextNumber}`}
              role="button"
              aria-label={`Click to call us at ${bookingData.extra.guestServicesTextNumber}`}
            >
              <div className="contact-row__info">
                <svg
                  className="contact-row__icon contact-row__icon--text"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="22"
                  height="32"
                >
                  <path
                    d="M208 416c0-26.5-21.5-48-48-48H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H309.3c-10.4 0-20.5 3.4-28.8 9.6L208 432v-16zm-.2 76.2.2-.2 101.3-76H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5l17.1-12.8 5.1-3.8zM96 180.8c0 12.3 6.2 23.8 16.4 30.7l29.4 19.6c1.3.9 2.2 2.4 2.2 4 0 2.7-2.2 4.8-4.8 4.8H112c-8.8 0-16 7.2-16 16s7.2 16 16 16h27.2c20.3 0 36.8-16.5 36.8-36.8 0-12.3-6.2-23.8-16.4-30.7l-29.4-19.6c-1.3-.9-2.2-2.4-2.2-4 0-2.7 2.2-4.8 4.8-4.8H152c8.8 0 16-7.2 16-16s-7.2-16-16-16h-19.2c-20.3 0-36.8 16.5-36.8 36.8zM372.8 144c-20.3 0-36.8 16.5-36.8 36.8 0 12.3 6.2 23.8 16.4 30.7l29.4 19.6c1.3.9 2.2 2.4 2.2 4 0 2.7-2.2 4.8-4.8 4.8H352c-8.8 0-16 7.2-16 16s7.2 16 16 16h27.2c20.3 0 36.8-16.5 36.8-36.8 0-12.3-6.2-23.8-16.4-30.7l-29.4-19.6c-1.3-.9-2.2-2.4-2.2-4 0-2.7 2.2-4.8 4.8-4.8H392c8.8 0 16-7.2 16-16s-7.2-16-16-16h-19.2zm-152 6.4c-4.1-5.5-11.3-7.8-17.9-5.6S192 153.1 192 160v96c0 8.8 7.2 16 16 16s16-7.2 16-16v-48l19.2 25.6c3 4 7.8 6.4 12.8 6.4s9.8-2.4 12.8-6.4L288 208v48c0 8.8 7.2 16 16 16s16-7.2 16-16v-96c0-6.9-4.4-13-10.9-15.2s-13.7.1-17.9 5.6L256 197.3l-35.2-46.9z"
                    fill="currentColor"
                  />
                </svg>
                <div className="contact-row__number-container">
                  <span className="contact-row__number-container__text">Text</span>
                  <span className="contact-row__number-container__number">
                    {bookingData.extra.guestServicesTextNumber}
                  </span>
                  <span className="contact-row__number-container__disclaimer">
                    * Data + Text fees may apply
                  </span>
                </div>
              </div>
            </a>
          )}
          {bookingData.extra.parkPhoneNumber && (
            <a
              className="contact-row__link"
              href={`tel:${bookingData.extra.parkPhoneNumber}`}
              role="button"
              aria-label={`Click to call us at ${bookingData.extra.parkPhoneNumber}`}
            >
              <div className="contact-row__info">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  fill="none"
                  className="contact-row__icon contact-row__icon--phone"
                >
                  <path
                    fill="currentColor"
                    d="M8.045 28a1.002 1.002 0 0 0 1 1h14a1.004 1.004 0 0 0 1-1v-5h-16v5Zm0-7.999h16V4a1.003 1.003 0 0 0-1-1h-14a1.003 1.003 0 0 0-1 1v16.001ZM5.045 4a4.004 4.004 0 0 1 4-4h14a4.004 4.004 0 0 1 4 4v24a4.003 4.003 0 0 1-4 4h-14a4.004 4.004 0 0 1-4-4V4Zm11 20.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
                  />
                </svg>
                <div className="contact-row__number-container">
                  <span className="contact-row__number-container__text">Call</span>
                  <span className="contact-row__number-container__number">
                    {bookingData.extra.parkPhoneNumber}
                  </span>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </section>
  );
}

export default ContactRow;
