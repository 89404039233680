import React, { createContext, useState, useEffect, useRef } from 'react';
import type { ReactNode } from 'react';

import { steps } from '../constants/steps';

type StepsContextProps = {
  children: ReactNode;
};

export type Step = {
  buttonLabel: string;
  heading?: string;
  value: string;
  active: boolean;
  done: boolean;
};

export type StepPosition = 'active' | 'prev' | 'next';

export type StepWillChange = { stepValue?: string; willChange: boolean };

export type StepsContextValueType = {
  steps: [Step[], React.Dispatch<React.SetStateAction<Step[]>>];
  activeStep: Step;
  goToStep: (stepValue: string) => void;
  beforeGoToStep: (stepValue: string) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  setAllStepsDone: () => void;
  stepWillChange: [StepWillChange, React.Dispatch<React.SetStateAction<StepWillChange>>];
};

export const StepsContext = createContext<StepsContextValueType | null>(null);

export default ({ children }: StepsContextProps) => {
  const [stepsData, setStepsData] = useState(steps);
  const [stepWillChange, setStepWillChange] = useState<StepWillChange>({ willChange: false });
  const isFirstLoad = useRef(true);

  // Add this to track active step changes
  const activeStep = stepsData.find(step => step.active);

  const goToStep = (stepValue: string) => {
    setStepsData(prevStepsData => {
      const newStepsData = [...prevStepsData];
      const stepIndex = newStepsData.findIndex(stepData => stepData.value === stepValue);
      const activeIndex = newStepsData.findIndex(stepData => stepData.active);
      if (activeIndex !== -1) newStepsData[activeIndex].active = false;
      newStepsData[stepIndex].active = true;
      return newStepsData;
    });
    setStepWillChange({ willChange: false });
  };

  const beforeGoToStep = (stepValue: string) => {
    setStepWillChange({ stepValue, willChange: true });
  };

  const goToNextStep = () => {
    setStepsData(prevStepsData => {
      const newStepsData = [...prevStepsData];
      const activeIndex = newStepsData.findIndex(stepData => stepData.active);
      newStepsData[activeIndex].done = true;
      newStepsData[activeIndex].active = false;
      if (newStepsData[activeIndex + 1]) newStepsData[activeIndex + 1].active = true;
      return newStepsData;
    });
  };

  const goToPrevStep = () => {
    setStepsData(prevStepsData => {
      const newStepsData = [...prevStepsData];
      const activeIndex = newStepsData.findIndex(stepData => stepData.active);
      newStepsData[activeIndex].active = false;
      newStepsData[activeIndex - 1].active = true;
      return newStepsData;
    });
  };

  const setAllStepsDone = () => {
    setStepsData(prevStepsData => {
      const newStepsData = [...prevStepsData];
      const formatedStepsData = newStepsData.map(({ buttonLabel, heading, value }) => {
        return {
          buttonLabel,
          heading,
          value,
          active: false,
          done: true
        };
      });

      return formatedStepsData;
    });
  };

  const value: StepsContextValueType = {
    steps: [stepsData, setStepsData],
    activeStep: stepsData.find(stepData => stepData.active) as Step,
    goToStep,
    goToPrevStep,
    goToNextStep,
    setAllStepsDone,
    beforeGoToStep,
    stepWillChange: [stepWillChange, setStepWillChange]
  };

  useEffect(() => {
    // Scroll whenever active step changes or on first load
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 500);
    isFirstLoad.current = false;
  }, [activeStep?.value]); // Only depend on the active step's value

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
};
