import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  turnJsonToHumanReadableString,
  turnHumanReadableStringToJson
} from '../../../helpers/humanReadable';

import { DataContext } from '../../../contexts/data';
import type {
  DataContextValueType,
  Product,
  BookingItem,
  SubProduct
} from '../../../contexts/data';

import { ModalContext } from '../../../contexts/modal';
import type { ModalContextValueType } from '../../../contexts/modal';

import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';

import { Option } from '../../../components/Dropdown';

import ErrorAlert from '../../../components/Alerts/Error';

import AddonsDescription from './AddonsDescription';
import IncludedAddons from './IncludedAddons';
import OptionalAddons from './OptionalAddons';
import AddonsPricing from './AddonsPricing';
import AddonsButtons from './AddonsButtons';

import API from '../../../api/api';

type AddOnsProps = {
  onNext: () => void;
};

const Addons = ({ onNext }: AddOnsProps) => {
  const {
    bookingData: [bookingData, setBookingData],
    bookingItems: [bookingItems, setBookingItems],
    editPackage: [editPackage],
    bookingDetails: [bookingDetails],
    productData,
    isEditMode,
    selectedPackage,
    externalId,
    partyNameString,
    isDecreasingJumpers: [isDecreasingJumpers, setIsDecreasingJumpers],
    hasBeenToReview: [, setHasBeenToReview]
  } = useContext(DataContext) as DataContextValueType;

  const {
    activeStep,
    goToPrevStep,
    stepWillChange: [stepWillChange, setStepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const [initialEditBookingAddons, setInitialEditBookingAddons] = useState<
    BookingItem[] | undefined
  >(undefined);
  const [initialEditBookingDate, setInitialEditBookingDate] = useState<string | undefined>(
    undefined
  );
  const [initialEditBookingTime, setInitialEditBookingTime] = useState<string | undefined>(
    undefined
  );

  const [includedAddons, setIncludedAddons] = useState<Product[]>([]);
  const [optionalAddons, setOptionalAddons] = useState<Product[]>([]);
  const [selectedSubProducts, setSelectedSubProducts] = useState<BookingItem[]>(bookingItems || []);
  const [initialSelectedSubproducts, setInitialSelectedSubproducts] = useState<BookingItem[]>(
    bookingItems || []
  );
  const [nextDisabled, setNextDisabled] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);
  const [packagePrice, setPackagePrice] = useState(0);
  const [addonsPrice, setAddonsPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const navigate = useNavigate();

  const [initialPricesFetched, setInitialPricesFetched] = useState(false);

  const getBookingItem = (
    addon: Product,
    subProduct: SubProduct,
    count: number,
    index?: number,
    isIncluded?: boolean
  ) => {
    return {
      productId: subProduct.id,
      quantity: count,
      cost: subProduct.cost,
      parentId: addon.id,
      index,
      name: subProduct.name,
      tax: subProduct.tax,
      parentName: addon.name,
      isIncluded,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime
    };
  };

  const parseAddonsData = () => {
    const packageItems = selectedPackage?.products[0].packageItems;
    const extraItemIds = selectedPackage?.addOns;

    const includedAddons = productData.filter(product =>
      packageItems?.find(item => item.parentProductId === product.id)
    );

    const includedAddonsWithExtraData = includedAddons.map(item => {
      return {
        ...item,
        quantityPerGuest:
          packageItems &&
          packageItems.find(packageItem => packageItem.parentProductId === item.id)
            ?.quantityPerGuest,
        quantityType:
          packageItems &&
          packageItems.find(packageItem => packageItem.parentProductId === item.id)?.quantityType,
        packageRequirement:
          packageItems &&
          packageItems.find(packageItem => packageItem.parentProductId === item.id)
            ?.packageRequirement
      };
    });

    const includedAddonsWithSubItems = includedAddonsWithExtraData.filter(
      addon => addon.products.length > 1
    );
    const includedAddonsWithoutSubItems = includedAddonsWithExtraData.filter(
      addon => addon.products.length === 1
    );

    const parsedBookingItems = includedAddonsWithoutSubItems.map(addon =>
      getBookingItem(
        addon,
        addon.products[0],
        addon.quantityType === 'package' ? addon.quantityPerGuest || 1 : getQuantity(addon),
        undefined,
        true
      )
    );

    if (editPackage && editPackage.quantity !== bookingData.extra.jumpersNumber) {
      const updatedBookingItems = bookingItems.map(item => ({
        ...item,
        bookingDate: bookingData.extra.bookingDate,
        startTime: bookingData.extra.startTime
      }));
      setSelectedSubProducts(updatedBookingItems);
      setInitialSelectedSubproducts(updatedBookingItems);
    }

    if (!isEditMode && bookingItems.length === 0) {
      setSelectedSubProducts(parsedBookingItems);
      setInitialSelectedSubproducts(parsedBookingItems);
    }

    if (!isEditMode && bookingItems.length > 0) {
      // Update each existing item with the current booking date/time
      const updatedBookingItems = bookingItems.map(item => ({
        ...item,
        bookingDate: bookingData.extra.bookingDate,
        startTime: bookingData.extra.startTime
      }));
      setSelectedSubProducts(updatedBookingItems);
      setInitialSelectedSubproducts(updatedBookingItems);
    }

    const optionalAddons = productData.filter(product => extraItemIds?.includes(product.id));

    setIncludedAddons(includedAddonsWithSubItems);
    setOptionalAddons(optionalAddons);
  };

  const handleSubProductChange = (
    count: number,
    addon: Product,
    subProduct: SubProduct,
    index?: number,
    isIncluded?: boolean
  ) => {
    setSelectedSubProducts((prevSelectedSubProducts): BookingItem[] => {
      const productIndex = prevSelectedSubProducts.findIndex(
        item => item.productId === subProduct.id
      );

      const newItem = getBookingItem(addon, subProduct, count, index, isIncluded);

      if (productIndex === -1) {
        setPrices([...prevSelectedSubProducts, newItem]);
        return [...prevSelectedSubProducts, newItem];
      }

      if (count === 0) {
        const updatedProducts = [...prevSelectedSubProducts];
        updatedProducts.splice(productIndex, 1);
        setPrices(updatedProducts);
        return updatedProducts;
      }

      const updatedProducts = [...prevSelectedSubProducts];

      if (
        updatedProducts[productIndex].index !== undefined &&
        updatedProducts[productIndex].index !== index
      ) {
        setPrices([...prevSelectedSubProducts, newItem]);
        return [...prevSelectedSubProducts, newItem];
      }

      updatedProducts[productIndex].quantity = count;
      updatedProducts[productIndex].index = index;

      setPrices(updatedProducts);
      return updatedProducts;
    });
  };

  const verifyNextDisabled = () => {
    const requiredAddons = includedAddons.filter(addon => addon.products.length > 1);
    const requiredAddonsSelectedLength = selectedSubProducts.filter(
      subProduct => subProduct.isIncluded && subProduct.index !== undefined
    ).length;

    const requiredAddonsLength = requiredAddons.reduce((acc, item) => {
      if (item.quantityPerGuest) {
        return acc + Math.floor(bookingData.extra.jumpersNumber / (item.quantityPerGuest || 1));
      }

      return acc + 1;
    }, 0);

    if (requiredAddonsLength === requiredAddonsSelectedLength) {
      setNextDisabled(false);
      return;
    }

    if (isEditMode) {
      if (!initialEditBookingAddons) return;
      //check booking notes from bookingDetails and compare to bookingData.extra
      if (!bookingDetails) return;
      const parsedBookingNotes = turnHumanReadableStringToJson(bookingDetails?.bookingNotes);

      if (
        bookingData.extra.guestOfHonorCount === 2 &&
        !parsedBookingNotes.secondGuestOfHonorDob &&
        requiredAddonsLength === requiredAddonsSelectedLength
      ) {
        setNextDisabled(false);
        return;
      }

      if (
        bookingData.extra.guestOfHonorCount === 3 &&
        !parsedBookingNotes.thirdGuestOfHonorDob &&
        requiredAddonsLength === requiredAddonsSelectedLength
      ) {
        setNextDisabled(false);
        return;
      }

      if (
        bookingData.extra.guestOfHonorCount === 1 &&
        parsedBookingNotes.secondGuestOfHonorDob &&
        requiredAddonsLength === requiredAddonsSelectedLength
      ) {
        setNextDisabled(false);
        return;
      }

      let hasChanged = false;
      bookingDetails?.items.forEach(item => {
        const selectedSubProduct = initialEditBookingAddons.find(
          subProduct => parseInt(subProduct.productId) === item.productId && !subProduct.isIncluded
        );

        if (selectedSubProduct && selectedSubProduct.quantity !== item.quantity) {
          hasChanged = true;
        }

        if (!selectedSubProduct && initialEditBookingAddons.length !== selectedSubProducts.length) {
          hasChanged = true;
        }
      });

      if (hasChanged && requiredAddonsLength === requiredAddonsSelectedLength) {
        setNextDisabled(false);
        return;
      }

      //check booking date and time
      if (
        initialEditBookingDate !== bookingData.extra.bookingDate ||
        initialEditBookingTime !== bookingData.extra.startTime
      ) {
        setNextDisabled(false);
        return;
      }
    }

    setNextDisabled(true);
  };

  const handleSelectChange = (option: Option, addon: Product, index: number) => {
    setSelectedSubProducts((prevSelectedSubProducts): BookingItem[] => {
      return prevSelectedSubProducts.filter(subProduct => {
        if (subProduct.parentId === addon.id && subProduct.index === index) {
          return false;
        }

        return true;
      });
    });

    const subProduct = addon.products.find(product => product.id === option.value) as SubProduct;

    handleSubProductChange(1, addon, subProduct, index, true);
  };

  const getBookingPayload = (discountCode?: string) => {
    const customer = {
      firstName: 'John',
      lastName: 'Doe',
      email: bookingData.customer.email,
      phone: '(000)-000-0000'
    };

    const packageItem = getPackageItem();

    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);

    const items = [...filteredBookingItems, packageItem];

    const payload = {
      name: partyNameString,
      externalId,
      customer,
      discounts: discountCode ? [{ code: discountCode }] : [],
      capacityReservationId: bookingData.extra.capacityReservationId || undefined,
      items
    };

    return payload;
  };

  const calculateBaseTotal = () => {
    if (!isEditMode) return 0;

    // Find the main package item (the one with startTime)
    const packageItem = getBookingPayload().items.find(item => item.startTime);
    if (!packageItem) return 0;

    // Calculate base package cost
    const packageCost = packageItem.cost * packageItem.quantity;

    // Calculate taxes for included items (partyPackageInclusions)
    const inclusionsTaxes =
      (packageItem.partyPackageInclusions as BookingItem[]).reduce(
        (acc: number, item: BookingItem) => {
          const taxAmount =
            item.cost * (item.index === undefined ? packageItem.quantity : 1) * (item.tax / 100);
          return acc + taxAmount;
        },
        0
      ) || 0;

    return packageCost + inclusionsTaxes;
  };

  const setPrices = async (subproducts: BookingItem[]) => {
    if (!selectedPackage) return;

    if (!initialPricesFetched && !isEditMode) {
      try {
        setInitialPricesFetched(true);
        showLoading();
        const payload = getBookingPayload();
        const costsResponse = await API.fetchBookingCosts(payload);

        if (costsResponse.errors) {
          openModal(
            <ErrorAlert
              title="Error"
              description={costsResponse.errors[0].message}
              onCloseModal={closeModal}
              buttons={['exitBooking']}
            />,
            false
          );
          return;
        }
        updatePrices(costsResponse.bookingCosts);
        hideLoading();
      } catch (error) {
        setInitialPricesFetched(false);
        openModal(
          <ErrorAlert
            title="Unknown error"
            description="Our server is returning an unknown error, please try again later"
            onCloseModal={closeModal}
            buttons={['exitBooking', 'reload']}
          />,
          false
        );
        console.error(error);
      }
      return;
    }

    const notIncludedItems = subproducts.filter(subproduct => !subproduct.isIncluded);
    const addonsTotal = notIncludedItems.reduce((acc, subproduct) => {
      return acc + subproduct.cost * subproduct.quantity;
    }, 0);

    // Get the base package price without any addons
    const baseTotal = isEditMode
      ? calculateBaseTotal()
      : bookingData.extra.prices?.total
      ? bookingData.extra.prices.total - (bookingData.extra.prices.addonsTotal || 0)
      : 0;

    // Calculate new total by adding the current addons total to the base package price
    const newTotal = baseTotal + addonsTotal;

    updatePrices({
      total: newTotal,
      addonsTotal: addonsTotal
    });
  };

  const updatePrices = (costs: { total: number; addonsTotal: number }) => {
    setPackagePrice((costs.total || 0) - (costs.addonsTotal || 0));
    setAddonsPrice(costs.addonsTotal || 0);
    setTotalPrice(costs.total || 0);

    setBookingData({
      ...bookingData,
      extra: {
        ...bookingData.extra,
        totalCost: costs.total || 0,
        prices: costs
      }
    });
  };

  const getComments = (): string => {
    const currentComments = turnHumanReadableStringToJson(bookingDetails?.comments || '');

    const comments = {
      accessibleAccommodations: currentComments.accessibleAccommodations,
      extraInfo: currentComments.extraInfo,
      guestOfHonorDob: bookingData.extra.guestOfHonorDob,
      secondGuestOfHonorDob: bookingData.extra.secondGuestOfHonorDob,
      thirdGuestOfHonorDob: bookingData.extra.thirdGuestOfHonorDob,
      nonJumpersNumber: bookingData.extra.nonJumpersNumber,
      remainingCapacity: bookingData.extra.remainingCapacity,
      Notes: currentComments.Notes
    };

    return turnJsonToHumanReadableString(comments);
  };

  const getPackageItem = () => {
    const includedItems = selectedSubProducts.filter(bookingItem => bookingItem.isIncluded);

    // Add default selections for included addons that haven't been chosen yet
    const defaultIncludedItems = includedAddons
      .filter(addon => !includedItems.some(item => item.parentId === addon.id))
      .flatMap(addon => {
        const defaultProduct = addon.products[0]; // Use first product as default
        const quantity =
          addon.quantityType === 'package'
            ? addon.quantityPerGuest || 1
            : Math.floor(bookingData.extra.jumpersNumber / (addon.quantityPerGuest || 1));

        // Create an array of items with index
        return Array.from({ length: quantity }, (_, i) => ({
          productId: defaultProduct.id,
          quantity: 1, // Always 1
          cost: defaultProduct.cost,
          parentId: addon.id,
          index: i, // Add index
          name: defaultProduct.name,
          tax: defaultProduct.tax,
          parentName: addon.name,
          isIncluded: true,
          bookingDate: bookingData.extra.bookingDate,
          startTime: bookingData.extra.startTime
        }));
      });

    const mappedIncludedItems = includedItems.map(bookingItem => {
      return {
        ...bookingItem,
        quantity: 1, // Ensure quantity is 1
        bookingDate: bookingData.extra.bookingDate,
        startTime: bookingData.extra.startTime
      };
    });

    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate || '',
      startTime: bookingData.extra.startTime || '',
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: [...mappedIncludedItems, ...defaultIncludedItems]
    };
  };

  const getUpdateBookingPayload = () => {
    const packageItem = getPackageItem();

    const notIncludedItems = selectedSubProducts.filter(bookingItem => !bookingItem.isIncluded);
    const mappedNotIncludedItems = notIncludedItems.map(bookingItem => {
      return {
        ...bookingItem,
        bookingDate: bookingData.extra.bookingDate,
        startTime: bookingData.extra.startTime
      };
    });
    const bookingItemIds = bookingDetails?.items.map(item => item.bookingItemId);

    const items = [packageItem, ...mappedNotIncludedItems];
    const idsToRemove = bookingItemIds;

    const payload = {
      name: partyNameString,
      comments: getComments(),
      newItems: items,
      idsToRemove
    };

    return payload;
  };

  const handleSaveEdits = async () => {
    showLoading();
    try {
      const payload = getUpdateBookingPayload();
      if (!bookingDetails) return;
      const updateBookingResponse = await API.updateBooking(bookingDetails.uniqueId, payload);
      if (updateBookingResponse.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={updateBookingResponse.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking']}
          />,
          false
        );
        return;
      }

      setBookingItems([]);
      navigate({
        pathname: '/review',
        search: window.location.search
      });
      return;
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
      return;
    } finally {
      hideLoading();
    }
  };

  const handleResetStep = () => {
    setSelectedSubProducts([]);
  };

  const handleSave = async (callback: () => void) => {
    if (isDecreasingJumpers) setIsDecreasingJumpers(false);
    if (nextDisabled) {
      setStepWillChange({ willChange: false });
      setIsSubmited(true);
      return;
    }

    setIsSubmited(true);
    setBookingItems(selectedSubProducts);

    // Set hasBeenToReview when proceeding to review
    setHasBeenToReview(true);

    if (isEditMode && !stepWillChange.stepValue) {
      await handleSaveEdits();
    }

    callback();
  };

  const handleNext = async () => {
    handleSave(onNext);
  };

  const handlePrev = async () => {
    if (bookingItems.length === 0) {
      handleResetStep();
    }
    await setPrices(initialSelectedSubproducts);
    goToPrevStep();
  };

  const handleStepChange = () => {
    if (bookingItems.length === 0) {
      handleResetStep();
    }

    stepWillChange.stepValue && goToStep(stepWillChange.stepValue);
  };

  const getQuantity = (addon: Product) => {
    if (!addon.quantityPerGuest) return 1;
    if (addon.quantityType === 'package') return addon.quantityPerGuest || 1;
    if (addon.packageRequirement === 'after')
      return Math.floor(bookingData.extra.jumpersNumber / addon.quantityPerGuest);
    if (addon.packageRequirement === 'before')
      return Math.ceil(bookingData.extra.jumpersNumber / addon.quantityPerGuest);

    return Math.floor(bookingData.extra.jumpersNumber / addon.quantityPerGuest);
  };

  useEffect(() => {
    if (isEditMode) {
      setInitialEditBookingAddons(selectedSubProducts);
      setInitialEditBookingDate(bookingData.extra.bookingDate);
      setInitialEditBookingTime(bookingData.extra.startTime);
      setIsSubmited(true);
    }
  }, []);

  useEffect(() => {
    parseAddonsData();
  }, [activeStep]);

  useEffect(() => {
    verifyNextDisabled();
  }, [selectedSubProducts, includedAddons]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-addons') {
      console.log('--- AddOns: activeStep effect ---');
      console.log('isDecreasingJumpers:', isDecreasingJumpers);
      console.log('bookingItems:', bookingItems);

      // Reset state when entering addons step
      setNextDisabled(false);
      setIsSubmited(false);

      if (isDecreasingJumpers) {
        // Reset selected subproducts when decreasing jumpers
        setSelectedSubProducts([]);
        setInitialSelectedSubproducts([]);
        // Reset prices when decreasing jumpers
        setPrices([]);
      } else if (bookingItems.length > 0) {
        // Only set prices if we have booking items and aren't decreasing jumpers
        setSelectedSubProducts(bookingItems);
        setInitialSelectedSubproducts(bookingItems);
        setPrices(bookingItems);
      }

      // Show validation messages if we need to select new addons
      const requiredAddons = includedAddons.filter(addon => addon.products.length > 1);
      const requiredAddonsLength = requiredAddons.reduce((acc, item) => {
        if (item.quantityPerGuest) {
          return acc + Math.floor(bookingData.extra.jumpersNumber / (item.quantityPerGuest || 1));
        }
        return acc;
      }, 0);

      const selectedRequiredAddons = selectedSubProducts.filter(
        subProduct => subProduct.isIncluded && subProduct.index !== undefined
      ).length;

      if (requiredAddonsLength > selectedRequiredAddons) {
        setIsSubmited(true);
        setNextDisabled(true);
      }

      parseAddonsData();
    }
  }, [activeStep, isDecreasingJumpers]);

  // Remove or modify this effect as it's now handled above
  useEffect(() => {
    if (!isDecreasingJumpers && bookingItems.length > 0 && activeStep?.value === 'select-addons') {
      setSelectedSubProducts(bookingItems);
      setInitialSelectedSubproducts(bookingItems);
    }
  }, [isDecreasingJumpers, bookingItems, activeStep?.value]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-addons' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-addons') {
      setPrices(selectedSubProducts);
    }
  }, [bookingData.extra.capacityReservationId]);

  useEffect(() => {
    // Re-parse addons data when date/time changes
    if (bookingData.extra.bookingDate && bookingData.extra.startTime) {
      parseAddonsData();
    }
  }, [bookingData.extra.bookingDate, bookingData.extra.startTime]);

  return (
    <>
      <AddonsDescription />

      <div className="add-ons-step">
        <IncludedAddons
          includedAddons={includedAddons}
          optionalAddons={optionalAddons}
          handleSubProductChange={handleSubProductChange}
          selectedSubProducts={selectedSubProducts}
          handleSelectChange={handleSelectChange}
          isFormSubmited={isSubmited}
        />

        <OptionalAddons
          includedAddons={includedAddons}
          optionalAddons={optionalAddons}
          selectedSubProducts={selectedSubProducts}
          handleSubProductChange={handleSubProductChange}
          activeStep={activeStep}
        />

        <AddonsPricing
          packageName={selectedPackage?.name}
          packagePrice={packagePrice}
          addonsPrice={addonsPrice}
          totalPrice={totalPrice}
        />
      </div>

      <AddonsButtons handlePrev={handlePrev} handleNext={handleNext} nextDisabled={nextDisabled} />
    </>
  );
};

export default Addons;
