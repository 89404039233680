import React, { useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType, Package, Session } from '../../../contexts/data';

import { ModalContext } from '../../../contexts/modal';
import type { ModalContextValueType } from '../../../contexts/modal';

import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';

import ErrorAlert from '../../../components/Alerts/Error';

import DateAndTimeDescription from './DateAndTimeDescription';
import DaySelector from './DaySelector';
import TimeSelector from './TimeSelector';
import DateAndTimeButtons from './DateAndTimeButtons';

import API from '../../../api/api';
import Alert from '../../../components/Alert';

type DateAndTimeProps = {
  onNext: () => void;
};

const DateAndTime = ({ onNext }: DateAndTimeProps) => {
  const {
    productId,
    bookingData: [bookingData, setBookingData],
    isEditMode,
    bookingDetails
  } = useContext(DataContext) as DataContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const {
    activeStep,
    goToPrevStep,
    stepWillChange: [stepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const [unavailableDays, setUnavailableDays] = useState<string[]>([]);
  const [showTimeAlert, setShowTimeAlert] = useState(false);
  const [showDateUnavailable, setShowDateUnavailable] = useState(false);
  const [availableSessions, setAvailableSesions] = useState<Session[]>([]);
  const [daySelected, setDaySelected] = useState<string>();
  const [timeSelected, setTimeSelected] = useState<string>();
  const [nextDisabled, setNextDisabled] = useState(true);
  const [showDiscountNotice, setShowDiscountNotice] = useState(false);
  const [showTimeClicksAlert, setShowTimeClicksAlert] = useState(false);
  const [isOverVenueLimit, setIsOverVenueLimit] = useState(false);

  // Add state to track number of date clicks
  const [dateClicksCount, setDateClicksCount] = useState(0);

  // Add state for alert presence (separate from fade-in)
  const [alertsVisible, setAlertsVisible] = useState(false);

  // Separate states for each alert's fade-in
  const [showDiscountAlert, setShowDiscountAlert] = useState(false);
  const [showTimeClicksAlertFade, setShowTimeClicksAlertFade] = useState(false);

  const discountNoticeAndTimeClicksRef = useRef<HTMLDivElement | null>(null);
  const timeTitleRef = useRef<HTMLHeadingElement | null>(null);
  const buttonsContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectedSessionCapacity, setSelectedSessionCapacity] = useState<{
    partyCapacity: number;
    remainingCapacity: number;
  } | null>(null);

  const getAvailableSessions = (sessions: Session[]) => {
    return sessions.filter(
      (session: Session) => session.capacityRemaining > 0 && session.onlineSalesOpen
    );
  };

  const onClickDay = async (date: Date | string, shouldScroll?: boolean) => {
    // Increment date clicks counter first
    setDateClicksCount(prev => {
      const newCount = prev + 1;
      // Just store the count, but don't show alert yet
      return newCount;
    });

    // Clear previous state
    setShowDiscountNotice(false);
    setAvailableSesions([]);
    setTimeSelected('');

    const sixMonthsAfterToday: Date = new Date(Date.now() + 180 * 24 * 60 * 60 * 1000);
    if (new Date(date) > sixMonthsAfterToday) {
      setShowTimeAlert(true);
      return;
    }

    setShowTimeAlert(false);
    setShowDateUnavailable(false);
    setNextDisabled(true);
    showLoading();

    try {
      const dateUS = typeof date === 'string' ? '' : date.toLocaleDateString('en-US');
      const splitedDate = dateUS.split('/');

      const month = parseInt(splitedDate[0], 10);
      const day = parseInt(splitedDate[1], 10);
      const year = parseInt(splitedDate[2], 10);

      const dateFormated = typeof date === 'string' ? date : `${year}-${month}-${day}`;

      const query = `?date=${dateFormated}&productids=${productId}`;
      const productAvailabilityData = await API.fetchProductAvailability(query);

      if (productAvailabilityData.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={productAvailabilityData.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking', 'close']}
          />
        );
        return;
      }

      const packageData = productAvailabilityData.find(
        (item: Package) => item.type === 'partypackage'
      );

      const availableSessionsData = getAvailableSessions(packageData.sessions);

      if (!availableSessionsData.length) {
        setShowDateUnavailable(true);
        setUnavailableDays([...unavailableDays, dateFormated]);
        setDaySelected('');
        hideLoading();
        return;
      }

      hideLoading();
      setDaySelected(dateFormated);
      setAvailableSesions(availableSessionsData);

      // Show alerts after loading is hidden
      if (
        dateClicksCount > 2 ||
        (shouldScroll &&
          isEditMode &&
          bookingDetails &&
          bookingData.extra.bookingDate !== dateFormated)
      ) {
        // First scroll to the alert container
        if (discountNoticeAndTimeClicksRef.current) {
          const elementPosition =
            discountNoticeAndTimeClicksRef.current.getBoundingClientRect().top + window.scrollY;
          let offset = 75;
          if (window.innerWidth > 1024) offset = 100;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth'
          });

          // First make alerts present but invisible
          setAlertsVisible(true);
          if (dateClicksCount > 2) {
            setShowTimeClicksAlert(true);
          }
          if (
            shouldScroll &&
            isEditMode &&
            bookingDetails &&
            bookingData.extra.bookingDate !== dateFormated
          ) {
            setShowDiscountNotice(true);
          }

          // After scroll complete, trigger fade-in
          setTimeout(() => {
            setShowDiscountAlert(true);
            setShowTimeClicksAlertFade(true);
          }, 500);
        }
      }
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  // Add new useEffect for handling scrolling
  useEffect(() => {
    if (!daySelected) return;

    setTimeout(() => {
      if (showTimeClicksAlert || showDiscountNotice) {
        // Scroll to alerts if they're showing
        if (discountNoticeAndTimeClicksRef.current) {
          const elementPosition =
            discountNoticeAndTimeClicksRef.current.getBoundingClientRect().top + window.scrollY;
          let offset = 75;
          if (window.innerWidth > 1024) offset = 100;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth'
          });
        }
      } else {
        // If no alerts, scroll to time section
        if (timeTitleRef.current) {
          const elementPosition = timeTitleRef.current.getBoundingClientRect().top + window.scrollY;
          let offset = 75;
          if (window.innerWidth > 1024) offset = 100;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: 'smooth'
          });
        }
      }
    }, 200);
  }, [daySelected, showTimeClicksAlert, showDiscountNotice]);

  // Update the reset logic
  useEffect(() => {
    if (timeSelected) {
      setDateClicksCount(0);
      setShowTimeClicksAlert(false);
    }
  }, [timeSelected]);

  // Reset clicks counter when entering the step
  useEffect(() => {
    if (activeStep?.value === 'select-date-time') {
      setDateClicksCount(0);
      setShowTimeClicksAlert(false);
    }
  }, [activeStep?.value]);

  // Update the existing useEffect for edit mode to include the reset
  useEffect(() => {
    if (activeStep && activeStep.value === 'select-date-time') {
      if (isEditMode) {
        handleEditLoad();
      }
      // Reset counter when entering the step
      setDateClicksCount(0);
      setShowTimeClicksAlert(false);
    }
  }, [activeStep]);

  const handleEditLoad = async () => {
    await onClickDay(bookingData.extra.bookingDate);
    setTimeSelected(bookingData.extra.startTime);
    setNextDisabled(false);
  };

  const checkVenueLimit = (bookableCapacityRemaining: number) => {
    if (bookingData.extra.jumpersNumber > bookableCapacityRemaining) {
      return true;
    }

    return false;
  };

  const handleSelectTime = (ev: React.ChangeEvent<HTMLInputElement>, session: Session) => {
    const isOverVenueLimit = checkVenueLimit(session.allocations[0].bookableCapacityRemaining);

    setTimeSelected(ev.target.value);

    // Store capacity values temporarily without updating bookingData
    setSelectedSessionCapacity({
      partyCapacity: session.allocations[0].bookableCapacityRemaining,
      remainingCapacity:
        session.allocations[0].bookableCapacityRemaining -
        ((bookingData.extra.jumpersNumber || 10) + (bookingData.extra.nonJumpersNumber || 0))
    });

    if (isOverVenueLimit) {
      setNextDisabled(true);
      setIsOverVenueLimit(true);
      return;
    }

    setIsOverVenueLimit(false);
    setNextDisabled(false);
  };

  const handleResetStep = () => {
    setDaySelected('');
    setTimeSelected('');
  };

  const handleNext = () => {
    // Check if we need a new time selection due to capacity
    const cameFromCapacityIssue =
      typeof bookingData.extra.remainingCapacity === 'number' &&
      bookingData.extra.remainingCapacity < 0;
    const hasSelectedNewTime = timeSelected !== bookingData.extra.startTime;

    if (cameFromCapacityIssue && !hasSelectedNewTime) {
      setIsOverVenueLimit(true);
      setNextDisabled(true);
      return;
    }

    if (!daySelected || !timeSelected) {
      setIsOverVenueLimit(true);
      return;
    }

    // Update bookingData with capacity values only when confirming selection
    setBookingData(prevData => ({
      ...prevData,
      extra: {
        ...prevData.extra,
        bookingDate: daySelected,
        startTime: timeSelected,
        partyCapacity: selectedSessionCapacity?.partyCapacity,
        remainingCapacity: selectedSessionCapacity?.remainingCapacity
      }
    }));

    onNext();
  };

  const handlePrev = () => {
    if (!bookingData.extra.bookingDate) {
      handleResetStep();
    }
    goToPrevStep();
  };

  const handleStepChange = () => {
    if (!bookingData.extra.bookingDate) {
      handleResetStep();
    }

    stepWillChange.stepValue && goToStep(stepWillChange.stepValue);
  };

  useEffect(() => {
    if (activeStep && activeStep.value === 'select-date-time' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  return (
    <>
      <DateAndTimeDescription />

      <div className="date-and-time container-sm">
        <DaySelector
          showTimeAlert={showTimeAlert}
          showDateUnavailable={showDateUnavailable}
          onClickDay={onClickDay}
          daySelected={daySelected}
          unavailableDays={unavailableDays}
        />
        <div ref={discountNoticeAndTimeClicksRef}>
          {alertsVisible && (
            <>
              {showDiscountNotice && (
                <Alert
                  className={classNames('steps-warning-message alert--fade', {
                    'alert--show': showDiscountAlert
                  })}
                  title={<>Discount may no longer apply</>}
                  type="default-font-black"
                >
                  Please note that any previously applied discount codes may no longer be valid
                  after rescheduling your party date.
                </Alert>
              )}
              {showTimeClicksAlert && (
                <Alert
                  className={classNames('steps-warning-message alert--fade', {
                    'alert--show': showTimeClicksAlertFade
                  })}
                  title={<>Party timing is everything!</>}
                  type="default-font-black"
                >
                  We noticed you are having trouble finding a time for your party. If you increased
                  your number of party attendees, either adjust your counts down or give us a call
                  at
                  {bookingData.extra.parkPhoneNumber} so we can help make the perfect accommodations
                  for your group!
                </Alert>
              )}
            </>
          )}
        </div>

        {!!availableSessions.length && daySelected && (
          <TimeSelector
            timeTitleRef={timeTitleRef}
            daySelected={daySelected}
            timeSelected={timeSelected}
            availableSessions={availableSessions}
            handleSelectTime={handleSelectTime}
            isOverVenueLimit={isOverVenueLimit}
          />
        )}
      </div>

      <DateAndTimeButtons
        buttonsContainerRef={buttonsContainerRef}
        handlePrev={handlePrev}
        handleNext={handleNext}
        nextDisabled={nextDisabled}
      />
    </>
  );
};

export default DateAndTime;
