import React from 'react';
import type { MouseEvent } from 'react';

function Footer() {
  type LegalItem = {
    label: string;
    href: string;
    id?: string;
  };

  const legal: LegalItem[] = [
    {
      label: 'Privacy Policy',
      href: 'https://www.skyzone.com/privacy-policy'
    },
    {
      label: 'Terms of Service',
      href: 'https://www.skyzone.com/terms-of-service'
    },
    {
      label: 'Your Privacy Rights',
      href: 'https://submit-irm.trustarc.com/services/validation/d982508e-c2b1-4850-805c-eb33c9438c81'
    },
    {
      label: 'Website Accessibility',
      href: 'https://www.skyzone.com/website-accesibility'
    }
  ];

  const getYear = () => {
    return new Date().getFullYear();
  };

  const handleExitBooking = (ev: MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  };

  return (
    <footer className="footer">
      <div className="footer__top">
        <a href="https://www.skyzone.com/parties/" onClick={handleExitBooking}>
          <picture className="footer__logo">
            <img
              src="/images/logo-skyzone-footer.svg"
              width="285"
              height="39"
              alt="Skyzone"
              loading="lazy"
            />
          </picture>
        </a>
      </div>
      <div className="footer__bottom">
        <span className="footer__copyright">© Copyright {getYear()} Sky Zone, LLC</span>

        <div className="footer__legal">
          {legal.map((item, index) => (
            <React.Fragment key={item.label}>
              <a target="_blank" id={item.id} href={item.href}>
                {item.label}
              </a>
              {index < legal.length - 1 && <span>|</span>}
            </React.Fragment>
          ))}
          <span>|</span>
          <div id="teconsent"></div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
